var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c(
          "el-button",
          {
            staticClass: "right",
            staticStyle: { width: "88px", height: "36px", margin: "10px 0" },
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("TitleModule", { attrs: { title: "设备信息" } }),
        _c("info-table", {
          attrs: {
            tableData: _vm.equipmentInfo,
            wrapperStyle: { border: 0 },
            num: 3,
            dataValue: _vm.equipmentInfoVal,
            valueStyle: { width: "calc(100% - 100px)", "text-align": "left" },
            nameStyle: {
              background: "#fff",
              width: "100px",
              "text-align": "right",
            },
          },
        }),
        _c("TitleModule", { attrs: { title: "故障报警信息" } }),
        _c("info-table", {
          attrs: {
            tableData: _vm.alertInfo,
            dataValue: _vm.equipmentInfoVal,
            wrapperStyle: { border: 0 },
            valueStyle: { width: "calc(100% - 100px)", "text-align": "left" },
            num: 3,
            nameStyle: {
              background: "#fff",
              width: "100px",
              "text-align": "right",
            },
          },
        }),
        _c("TitleModule", { attrs: { title: "处理进度" } }),
        _c("info-table", {
          attrs: {
            tableData: _vm.processInfo,
            dataValue: _vm.processing,
            wrapperStyle: { border: 0 },
            valueStyle: { width: "calc(100% - 100px)", "text-align": "left" },
            num: 3,
            nameStyle: {
              background: "#fff",
              width: "100px",
              "text-align": "right",
            },
          },
          scopedSlots: _vm._u(
            [
              _vm._l(
                _vm.equipmentInfoVal.areaFaultInfoList,
                function (val, ind) {
                  return {
                    key: val.valueKey,
                    fn: function () {
                      return [
                        _c(
                          "div",
                          {
                            key: ind,
                            staticClass: "reason",
                            attrs: { title: val.faultReason },
                          },
                          [_vm._v(" " + _vm._s(val.faultReason) + " ")]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                }
              ),
              _vm._l(
                _vm.equipmentInfoVal.areaFaultInfoList,
                function (val, ind) {
                  return {
                    key: val.timeKey,
                    fn: function () {
                      return [
                        val.faultInfoStatus != 3
                          ? _c(
                              "div",
                              {
                                key: ind,
                                staticClass: "reason",
                                attrs: { title: val.createdTime },
                              },
                              [_vm._v(" " + _vm._s(val.createdTime) + " ")]
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  }
                }
              ),
            ],
            null,
            true
          ),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }